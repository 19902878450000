// @import "D:\Sites\kidzone_inflatable_hire_24_05_17\Autobopr\Assets\functions.less";
@import "../Autobopr/Assets/Functions.less";

.banner {
	#CarouselContainer {
		img {
			max-height: 680px;
			width: 100%;
			object-fit: cover;
		}

		@media (max-width: 1349px) {
			padding-top: 50%;
		}

		@media @mobile {
			padding-top: 60%;
		}
	}
}